import { template as template_d752dfc4223c44a5a03a359f169908a5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_d752dfc4223c44a5a03a359f169908a5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
