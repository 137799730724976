import { template as template_c4741bb2658b42c990fb390cb6353ebe } from "@ember/template-compiler";
const FKText = template_c4741bb2658b42c990fb390cb6353ebe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
