import { template as template_0b41c61bfcf34404a4f35821157a732e } from "@ember/template-compiler";
const FKLabel = template_0b41c61bfcf34404a4f35821157a732e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
